import React, { useRef } from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import {
  SectionHeading,
  Subheading as SubheadingBase,
} from "components/misc/Headings.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import EmailIllustrationSrc from "images/email-illustration.svg";
import axios from "axios";
import { useEffect } from "react";
import ReCAPTCHA from "react-google-recaptcha";

const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-20 md:py-24`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-5/12 flex-shrink-0 h-80 md:h-auto`;
const TextColumn = styled(Column)((props) => [
  tw`md:w-7/12 mt-16 md:mt-0`,
  props.textOnLeft
    ? tw`md:mr-12 lg:mr-16 md:order-first`
    : tw`md:ml-12 lg:ml-16 md:order-last`,
]);

const Image = styled.div((props) => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded bg-contain bg-no-repeat bg-center h-full`,
]);
const TextContent = tw.div`lg:py-8 text-center md:text-left`;

const Subheading = tw(SubheadingBase)`text-center md:text-left`;
const Heading = tw(
  SectionHeading
)`mt-4 font-black text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;
const Description = tw.p`mt-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`;

const Form = tw.form`mt-8 md:mt-10 text-sm flex flex-col max-w-sm mx-auto md:mx-0`;
const Input = tw.input`mt-6 first:mt-0 border-b-2 py-3 focus:outline-none font-medium transition duration-300 hocus:border-primary-500`;
const Textarea = styled(Input).attrs({ as: "textarea" })`
  ${tw`h-24`}
`;

const SubmitButton = tw(PrimaryButtonBase)`inline-block mt-8`;

export default ({
  subheading = "Contact Us",
  heading = (
    <>
      Feel free to <span tw="text-primary-500">get in touch</span>
      <wbr /> with us.
    </>
  ),
  description = "The Following Fields are Compulsory",
  submitButtonText = "Send",
  formMethod = "post",
  textOnLeft = true,
}) => {
  // The textOnLeft boolean prop can be used to display either the text on left or right side of the image.
  const [name, setName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [message, setMessage] = React.useState("");
  const [subject, setSubject] = React.useState("");
  const reCaptchaRef = useRef(null);

  const submitFunction = (e) => {
    e.preventDefault();
    if (reCaptchaRef.current.getValue() === "") {
      alert("reCaptcha verification failed");
      return;
    }
    if (name === "" || email === "" || message === "" || subject === "") {
      alert("Please fill all the fields");
      return;
    }
    axios
      .post(
        // "http://localhost:5000/contact",
        "https://mysterious-plateau-99099.herokuapp.com/contact",
        {
          name,
          from: email,
          message,
          subject,
          captcha: reCaptchaRef.current.getValue(),
        },
        { headers: { "Content-Type": "application/json" } }
      )
      .then((res) => {
        if (res.status === 200) {
          alert(res.data.status);
          setName("");
          setEmail("");
          setMessage("");
          setSubject("");
        } else {
          alert("Message failed to send.");
        }
      });
  };

  return (
    <Container>
      <TwoColumn>
        <ImageColumn>
          <Image imageSrc={EmailIllustrationSrc} />
        </ImageColumn>
        <TextColumn textOnLeft={true}>
          <TextContent>
            <Subheading>Contact Us</Subheading>
            <Heading>{heading}</Heading>
            <Description>
              You can send your queries and further contact via:{" "}
            </Description>
            <SubmitButton
              type="link"
              onClick={() => {
                window.open("mailto:virudhhiedu.consultant@gmail.com");
              }}
            >
              Email
            </SubmitButton>
            <br />
            <SubmitButton
              type="link"
              onClick={() => {
                window.open(
                  "mailto:virudhttps://api.whatsapp.com/send/?phone=%2B919442359937&text=Hi%2C+I+visited+your+website+and+would+like+to+know+more+about+Gururbrahma+Academy.&type=phone_number&app_absent=0hhiedu.consultant@gmail.com"
                );
              }}
            >
              WhatsApp
            </SubmitButton>
            <br />
            <SubmitButton
              type="link"
              onClick={() => {
                window.open("tel:+919443514199");
              }}
            >
              Call
            </SubmitButton>

            <br />

            <SubmitButton
              type="link"
              onClick={() => {
                window.open("https://www.youtube.com/@gururbrahma");
              }}
            >
              YouTube
            </SubmitButton>

            {/* <Form
              action="http://localhost:5000/contact"
              onSubmit={submitFunction}
            >
              <Input
                type="email"
                name="from"
                placeholder="Your Email Address"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <Input
                type="text"
                name="name"
                placeholder="Full Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
              <Input
                type="text"
                name="subject"
                placeholder="Subject"
                value={subject}
                onChange={(e) => setSubject(e.target.value)}
              />
              {/* 
              <Input
                type="file"
                name="attatch"
                placeholder="Upload your resume"
              />
               
              <Textarea
                name="message"
                value={message}
                placeholder="Your Message Here"
                onChange={(e) => setMessage(e.target.value)}
              />
              <ReCAPTCHA
                theme="light"
                size="normal"
                sitekey={"6LfHBFUiAAAAANfSpq-CkUsGju9Bdcd_O5-I9laL"}
                ref={reCaptchaRef}
              />
              <SubmitButton type="submit">{submitButtonText}</SubmitButton>
            </Form> */}
          </TextContent>
        </TextColumn>
      </TwoColumn>
    </Container>
  );
};
