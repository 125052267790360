import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, Content2Xl } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro";
import GitHubButton from "react-github-btn";
//import Header from "components/headers/light.js";
import Header from "components/slideshow/Hero.js";
import { LogoLink } from "components/headers/light.js";
import { SectionHeading as HeadingBase } from "components/misc/Headings";
import Slideshow from "components/slideshow/Slideshow";
import { SectionDescription as DescriptionBase } from "components/misc/Typography";
import Footer from "components/footers/Footer";
import GetStartedLight from "components/cta/GetStartedLight";
import MainFeature from "components/features/Featues";

/* Hero */
const Row = tw.div`flex`;
const NavRow = tw(Row)`flex flex-col lg:flex-row items-center justify-between`;
const NavLink = tw.a`mt-4 lg:mt-0 transition duration-300 font-medium pb-1 border-b-2 lg:mr-12 last:mr-0 text-gray-700 border-gray-400 hocus:border-gray-700 `;
const PrimaryNavLink = tw(
  NavLink
)`text-gray-100 bg-primary-800 px-6 py-3 border-none rounded hocus:bg-primary-900 focus:shadow-outline`;
const HeroRow = tw(Row)`max-w-xl flex-col justify-between items-center py-20 lg:py-24 mx-auto`;

const Heading = tw(HeadingBase)`text-center text-primary-900 leading-snug`;
const Description = tw(DescriptionBase)`mt-4 text-center lg:text-base text-gray-700 max-w-lg mx-auto lg:mx-0`;

export default () => {
  return (
    <AnimationRevealPage>

      <Header />
      <MainFeature id="about"/>
      <GetStartedLight 
        subheading = "Searching for teaching jobs?"
        heading = "Apply to top schools"
        primaryLinkText = "Get Started"
        primaryLinkUrl = "/services"
        secondaryLinkText = "Contact Us"
        secondaryLinkUrl = "/contact"
      />
      <Footer id="contact"/>
    </AnimationRevealPage>
  );
};
