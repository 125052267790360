import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import Header from "components/headers/light.js";
import Footer from "components/footers/Footer";
import Student from "components/features/Student";
import Teacher from "components/features/Teacher";
import ConnectedWith from "components/features/ConnectedWith";

export default () => {
  return (
    <AnimationRevealPage>
      <Header />
      <Student />
      {/* 
        
        <Teacher />
        <ConnectedWith />
         */}
      <Footer />
    </AnimationRevealPage>
  );
};
