import React from "react";
import Carousel from "react-multi-carousel";
//import "react-responsive-carousel/lib/styles/carousel.min.css";
import "react-multi-carousel/lib/styles.css";
import image1 from "images/web/Gururbrahma 1-min.png";
import image2 from "images/web/Gururbrahma 2-min.png";
import image3 from "images/web/Gururbrahma 3-min.png";
import image4 from "images/web/Gururbrahma 5-min.png";
import image5 from "images/web/Gururbrahma 8-min.png";
import image6 from "images/web/Gururbrahma 13-min.png";
import image7 from "images/web/Gururbrahma 10-min.png";
import image8 from "images/web/Gururbrahma 4-min.png";
import image9 from "images/web/Gururbrahma 6-min.png";
import image10 from "images/web/Gururbrahma 7-min.png";
import image11 from "images/web/Gururbrahma 9-min.png";
import image12 from "images/web/Gururbrahma 11-min.png";
import image13 from "images/web/Gururbrahma 12-min.png";
import image14 from "images/web/Gururbrahma 14-min.png";

const fadeImages = [
  {
    url: image1,
  },
  {
    url: image2,
  },
  {
    url: image3,
  },
  {
    url: image4,
  },
  {
    url: image5,
  },
  {
    url: image6,
  },
  {
    url: image7,
  },
  {
    url: image8,
  },
  {
    url: image9,
  },
  {
    url: image10,
  },
  {
    url: image11,
  },
  {
    url: image12,
  },
  {
    url: image13,
  },
  {
    url: image14,
  },
];
const responsive = {
  mobile: {
    breakpoint: { max: 4000, min: 0 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
};
const style = {
  container: {},
};

export default () => {
  return (
    <div
      style={{
        maxWidth: "800px",
        maxHeight: "400px",
      }}
    >
      <Carousel
        responsive={responsive}
        arrows={false}
        showDots={true}
        infinite={true}
        autoPlay={true}
        autoPlaySpeed={1500}
        keyBoardControl={true}
        customTransition="all .5"
        transitionDuration={1600}
        containerClass="carousel-container"
        dotListClass="custom-dot-list-style"
        itemClass="carousel-item-padding-40-px"
      >
        {fadeImages.map((fadeImage) => (
          <div>
            <img
              class="img-fluid"
              src={fadeImage.url}
              onContextMenu="return false;"
            />
          </div>
        ))}
      </Carousel>
    </div>
  );
};
