import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
//eslint-disable-next-line
import { css } from "styled-components/macro";

import Header from "../headers/light.js";

import { ReactComponent as SvgDecoratorBlob1 } from "../../images/svg-decorator-blob-1.svg";
import DesignIllustration from "../../images/design-illustration-2.svg";
import CustomersLogoStripImage from "../../images/customers-logo-strip.png";
import Slideshow from "./Slideshow.js";

const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col lg:flex-row lg:items-center max-w-screen-xl mx-auto py-20 md:py-24`;
const LeftColumn = tw.div`relative lg:w-5/12 text-center max-w-lg mx-auto lg:max-w-none lg:text-left`;
const RightColumn = tw.div`relative mt-12 lg:mt-0 flex-1 flex flex-col justify-center lg:self-end`;

const Heading = tw.h1`font-bold text-3xl md:text-3xl lg:text-4xl xl:text-5xl text-gray-900 leading-tight`;
const Paragraph = tw.p`my-5 lg:my-8 text-base xl:text-lg`;
const ColumnContainer = tw.div`lg:w-1/2 max-w-lg`;
const LinksContainer = tw(
  ColumnContainer
)`flex justify-center lg:justify-center mt-6 lg:mt-0 flex-col sm:flex-row`;
const Link = tw.a`w-full sm:w-auto text-sm sm:text-base px-6 py-3 sm:px-8 sm:py-4 lg:px-10 lg:py-5 mt-4 first:mt-0 sm:mt-0 sm:mr-8 sm:last:mr-0 rounded font-bold border border-transparent tracking-wide transition duration-300 focus:outline-none focus:shadow-outline`;
const PrimaryLink = tw(
  Link
)`shadow text-gray-100 hocus:text-gray-300 bg-primary-700 hocus:bg-primary-800`;

const SecondaryLink = tw(
  Link
)`text-primary-500 hover:text-primary-700 bg-gray-100 hover:bg-gray-200`;

const Actions = styled.div`
  ${tw`relative max-w-md text-center mx-auto lg:mx-0`}
  input {
    ${tw`sm:pr-48 pl-8 py-4 sm:py-5 rounded-full border-2 w-full font-medium focus:outline-none transition duration-300  focus:border-primary-500 hover:border-gray-500`}
  }
  button {
    ${tw`w-full sm:absolute right-0 top-0 bottom-0 bg-primary-500 text-gray-100 font-bold mr-2 my-4 sm:my-2 rounded-full py-4 flex items-center justify-center sm:w-40 sm:leading-none focus:outline-none hover:bg-primary-900 transition duration-300`}
  }
`;

const IllustrationContainer = tw.div`flex justify-center lg:justify-end items-center`;

// Random Decorator Blobs (shapes that you see in background)
const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
  ${tw`pointer-events-none opacity-5 absolute left-0 bottom-0 h-64 w-64 transform -translate-x-2/3 -z-10`}
`;

const CustomersLogoStrip = styled.div`
  ${tw`mt-12 lg:mt-20`}
  p {
    ${tw`uppercase text-sm lg:text-xs tracking-wider font-bold text-gray-500`}
  }
  img {
    ${tw`mt-4 w-full lg:pr-16 xl:pr-32 opacity-50`}
  }
`;

export default ({ roundedHeaderButton }) => {
  return (
    <>
      <Header roundedHeaderButton={roundedHeaderButton} />
      <Container>
        <TwoColumn>
          <LeftColumn>
            <Heading
              style={{
                fontFamily: "Poppins",
              }}
            >
              <span tw="text-primary-500">गुरुः</span> साक्षात् परं{" "}
              <span tw="text-primary-500">ब्रह्म</span>
            </Heading>
            <Paragraph>
              A platform for students and teachers to connect and learn. Find Us
              on <br />
              <LinksContainer>
                <PrimaryLink
                  target="_blank"
                  href="https://api.whatsapp.com/send/?phone=%2B919442359937&text=Hi%2C+I+visited+your+website+and+would+like+to+know+more+about+Gururbrahma+Academy.&type=phone_number&app_absent=0"
                >
                  WhatsApp
                </PrimaryLink>
                <SecondaryLink
                  href="https://www.youtube.com/@gururbrahma"
                  target="_blank"
                >
                  YouTube
                </SecondaryLink>
              </LinksContainer>
            </Paragraph>
          </LeftColumn>
          <RightColumn>
            <Slideshow />
          </RightColumn>
        </TwoColumn>
        <DecoratorBlob1 />
      </Container>
    </>
  );
};
