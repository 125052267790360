import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { ReactComponent as SvgDotPatternIcon } from "../../images/svg-decorator-blob-2.svg";
import { SectionHeading as HeadingTitle } from "../misc/Headings.js";
import image1 from "../../images/web/sathya-ram/sathya-ram1.JPG";
import image2 from "../../images/web/sathya-ram/sathya-ram2.JPG";
import posterimage from "../../images/web/sathya-ram/poster-purple.png";

const Container = tw.div`relative`;

const SingleColumn = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;

const HeadingInfoContainer = tw.div`flex flex-col items-center`;
const HeadingDescription = tw.p`mt-4 font-medium text-gray-600 text-center max-w-sm`;

const Content = tw.div`mt-16`;

const Card = styled.div((props) => [
  tw`mt-24 md:flex justify-center items-center`,
  props.reversed ? tw`flex-row-reverse` : "flex-row",
]);
const Image = styled.div((props) => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded md:w-1/2 lg:w-5/12 xl:w-1/3 flex-shrink-0 h-80 md:h-144 bg-cover bg-center mx-4 sm:mx-8 md:mx-4 lg:mx-8`,
]);
const Details = tw.div`mt-4 md:mt-0 md:max-w-md mx-4 sm:mx-8 md:mx-4 lg:mx-8`;
const Subtitle = tw.div`font-bold tracking-wide text-secondary-100`;
const Title = tw.h4`text-3xl font-bold text-gray-900`;
const Description = tw.p`mt-2 text-sm leading-loose`;
const Link = tw.a`inline-block mt-4 text-sm text-primary-500 font-bold cursor-pointer transition duration-300 border-b-2 border-transparent hover:border-primary-500`;

const SvgDotPattern1 = tw(
  SvgDotPatternIcon
)`absolute top-0 left-0 transform -translate-x-20 rotate-90 translate-y-8 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern2 = tw(
  SvgDotPatternIcon
)`absolute top-0 right-0 transform translate-x-20 rotate-45 translate-y-24 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern3 = tw(
  SvgDotPatternIcon
)`absolute bottom-0 left-0 transform -translate-x-20 rotate-45 -translate-y-8 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern4 = tw(
  SvgDotPatternIcon
)`absolute bottom-0 right-0 transform translate-x-20 rotate-90 -translate-y-24 -z-10 opacity-25 text-primary-500 fill-current w-24`;

export default () => {
  const cards = [
    {
      imageSrc: posterimage,
      subtitle: "Launching...",
      title: "GURURBRAHMA ACADEMY",
      description: (
        <div>
          <p>
            Gururbrahma Academy is our online platform where we provide various
            classes for students and adults.
          </p>
          <br />
          <p>
            Hindi classes are provided for students from class 1 to 10, and also
            preparation for the Dakshin Bharat Hindi Prachar Sabha Examinations
            are provided. Sanskrit classes are taken for students from class 1
            to 12.
          </p>
          <br />
          <p>
            Philosophical classes are taken for both students and adults. These
            classes include various important shlokas and their meanings, the
            Bhagavad Gita and the Naalayira Divya Prabandham.
          </p>
          <br />
          <p>
            Kindly follow us on{" "}
            <Link href="https://www.youtube.com/@gururbrahma">YouTube</Link> for
            more updates.
          </p>
          <br />
          <p>
            For more details, you can WhatsApp us at{" "}
            <Link href="https://api.whatsapp.com/send/?phone=%2B919442359937&text=Hi%2C+I+visited+your+website+and+would+like+to+know+more+about+Gururbrahma+Academy.&type=phone_number&app_absent=0">
              9442359937
            </Link>
          </p>
        </div>
      ),
    },
    {
      imageSrc: image1,
      subtitle: "Founder",
      title: "Mr. B. M. Sathya Ram",
      description: (
        <div>
          <p>
            <i>"On earth there is no purifier as great as knowledge"</i>
            <br />
            <i>- Srimad Bhagavad Gita (Chapter 4, Verse 38)</i>
          </p>
          <br />
          <p>
            Our founder has been a resourceful and hardworking individual in the
            field of education for over 26 years. With a Post Graduation in
            Sanskrit, Hindi and English language and literature, he has been
            able to develop a strong sense of communication with the students
            and continues to instill passion in the same amongst thousands.{" "}
          </p>
          <br />
          <p>
            He has been passionate about conducting various workshops for
            teachers that helps them in developing a rich communication and an
            exciting atmosphere of learning with the students.
          </p>
          <br />
          <p>
            {" "}
            He has also been a guiding force in administration of various
            schools and helped them in developing policies and curriculum that
            promotes the all-round development of the students as well as
            teachers. He has been associated with numerous reputed institutions
            for attaining affliation and upgradation from the Board of
            Education.
          </p>
        </div>
      ),
      // "A dedicated and resourceful educator with a proven ability to create and monitor policies and practices that promote a safe learning environment, thus ensuring a school culture that encourages continuous improvements for teachers and students; develops an environment that encourages open communication with peers, students, and the community; mentors educators in the creation and implementation of class instructions, lesson plans, and student assessment in conjunction with learning standards.",
    },

    {
      imageSrc: image2,
      title: "Vision",
      description: (
        <div>
          <p>
            Gururbrahma Services have the vision to help in the upliftment of
            the educational institutions.
          </p>
          <Title className="mt-3">Mission</Title>
          <p>
            The Students, Teachers, Parents and the Management are the four
            pillars of any institution. The institution can be very strong, if
            they all come and work together. <br />
            We provide consultancy services to improve the strength of the
            faculty, overall development of students, councelling for parents
            and tactical solution for the management.
          </p>
        </div>
      ),
      url: "/services",
    },
  ];

  return (
    <Container>
      <SingleColumn>
        <HeadingInfoContainer>
          <HeadingTitle>About Us</HeadingTitle>
          <HeadingDescription></HeadingDescription>
        </HeadingInfoContainer>

        <Content>
          {cards.map((card, i) => (
            <Card key={i} reversed={i % 2 === 1}>
              <Image imageSrc={card.imageSrc} />
              <Details>
                <Subtitle>{card.subtitle}</Subtitle>
                <Title>{card.title}</Title>
                <Description>{card.description}</Description>
                {card.url ? (
                  <Link href={card.url}>See More Details</Link>
                ) : (
                  <p></p>
                )}
              </Details>
            </Card>
          ))}
        </Content>
      </SingleColumn>
      <SvgDotPattern1 />
      <SvgDotPattern2 />
      <SvgDotPattern3 />
      <SvgDotPattern4 />
    </Container>
  );
};
